.tab-container {
	margin-top: 2%;
	width: 50%;

	border-bottom: 1px solid #ccc;
	column-gap: 20px;
}

.tab-buttons {
	display: flex;
}

.tab-button {
	flex: 1;
	padding: 5px;
	text-align: center;
	background-color: white;
	border: none;
	cursor: pointer;
	color: black;
}

.tab-button.active {
	color: #ff5a00;
	font-size: 20px;
	transition: 0.2s ease-out;
	border-bottom: 3px solid #ff5a00;
}

.tab-pane {
	padding: 20px;
}

@media (max-width: 600px) {
	.tab-button.active {
		font-size: 17px;
	}
	.tab-container {
		width: 100%;
	}
}
@media (max-width: 750px) {
	.form-main {
		row-gap: 1rem;
	}
}
