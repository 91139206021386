.pricing-container {
	margin-top: 2%;
}
.plans-container {
	border: 1px solid rgb(179, 177, 177);
	padding: 1rem;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	row-gap: 0.5rem;
	background-color: rgb(243, 243, 243);
}
.label-component {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	column-gap: 1rem;
	row-gap: 1rem;
	flex-wrap: wrap;
}
.dropdown {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: black;
	background-color: white;
}
.dropdown:hover {
	background-color: white;
	color: #000000;
	border-radius: 5px;
}

.equal-width {
	width: 350px;
}
.icon {
	width: 24px;
	height: 24px;
	color: #ff5a00;
}
.input-group {
	border: 1px solid rgb(179, 177, 177);
	display: flex;
	align-items: center;
	border-radius: 5px;
	padding-right: 5px;
	background-color: white;
}
.input-field {
	border: none;
	margin-right: 1px;
}

.input-field:focus {
	outline: none;
	box-shadow: none;
}
.button,
.button:hover {
	background-color: #ff5a00;
	margin: 0 auto;
	font-size: 18px;
	font-weight: 500;
	border: none;
	margin-top: 20px;
}
a {
	text-decoration: none;
	color: #f05b26;
}
.section {
	padding-top: 20px;
}
.service-container {
	margin-top: 0px;
}
.table--features table,
.table--features thead,
.table--features th {
	background-color: #e4e4e7;
}
.card-container {
	margin-top: 40px;
}
.pricing-card {
	align-items: center;
	padding: 3rem;

	border-top: 1px solid rgb(220, 220, 220);
	border-right: 1px solid rgb(220, 220, 220);
	border-bottom: 1px solid rgb(220, 220, 220);
	border-radius: 10px;
	row-gap: 1rem;
	border-left: 5px solid #f05b26;
}
.pricing-card > button {
	min-width: 200px;
}
.btn-outline {
	color: #f05b26;
	border: 1px solid #f05b26;
}
.btn-outline:hover {
	color: white;
	background-color: #f05b26;
	border: none;
}
.card-container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	flex-wrap: wrap;
}
.pricing-card:hover {
	background-color: #fafafa;
}
.title {
	margin-top: 3%;
}
.most-popular {
	position: absolute;
	top: -10px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #8e2de2;

	color: white;
	padding: 5px 15px;
	border-radius: 20px;
	font-weight: bold;
	font-size: 8px;
}
@media (max-width: 1300px) {
	.equal-width {
		width: 300px;
	}
}

@media (max-width: 1200px) {
	.equal-width {
		width: 300px;
	}
}
@media (max-width: 1100px) {
	.equal-width {
		width: 290px;
	}
}
@media (max-width: 800px) {
	.label-component {
		flex-direction: column;
	}
	.equal-width {
		width: 300px;
	}
}
@media (max-width: 530px) {
	.equal-width {
		width: 300px;
	}
	.label-component {
		flex-direction: column;
	}
}
@media (max-width: 320px) {
	.equal-width {
		width: 250px;
	}
	.plans-container {
		padding: 0.1rem;
	}
}
