.terms-container {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.terms-container h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
      text-align: center;
    margin-bottom: 50px;
}

.terms-container h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.terms-container h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.terms-container p {
  margin-bottom: 10px;
}

.terms-container ul, ol {
  margin-bottom: 10px;
  margin-left: 20px;
}

.terms-container strong {
  font-weight: bold;
}
