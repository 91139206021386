.fade-appear,
.fade-enter {
	opacity: 0;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 500ms ease-in;
}

.fade-exit {
	opacity: 1;
}

.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 500ms ease-out;
}

.register-page-container2 {
	/* background: #f6f6f6; */
	background-image: radial-gradient(circle, #fff, rgba(42, 42, 42, 0.1));
	background-repeat: no-repeat;
	height: 100vh;
	/* display: flex; */
	justify-content: center;
	align-items: center;
	overflow-x: hidden;
}

.register-container2 {
	/* padding-top: 50px;
  padding-bottom: 50px; */
}

.register-container2 h1 {
	/* margin-bottom: 30px; */
	background: rgba(7, 10, 9, 0.66);
	color: white;
	padding: 5px 0px;
}

.register-step2-form {
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.register-step2-form .form-group {
	margin-bottom: 20px;
}

.register-step2-form label {
	font-weight: 500;
}

.register-step2-form .form-control {
	font-weight: 300 !important;
}

.register-step2-form .btn-secondary {
	padding: 5px 50px;
	background-color: #fff;
	border-color: silver;
	color: #61666b;
}

.register-step2-form .btn-secondary:hover {
	background-color: #e6e6e6;
	border-color: #e6e6e6;
}

.register-step2-form .btn-primary {
	padding: 5px 50px;
	background-color: #f06331;
	border-color: #f06331;
}

.register-step2-form .btn-primary:hover {
	background-color: #cc4800;
	border-color: #cc4800;
}

.register-step2-form .btn-discount {
	padding: 5px 50px;
	background-color: #026928;
	border-color: #026928;
	color: white;
}

.register-step2-form .btn-discount:hover {
	background-color: #026928;
	border-color: #026928;
	color: white;
}

.register-step2-form .show-password-icon {
	height: 100%;
}

.register-step2-form .input-group {
	position: relative;
}

.register-step2-form .show-password-icon {
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
	cursor: pointer;
}

.register-step2-form .input-group .form-control {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.register-step2-form .form-row {
	display: flex;
	justify-content: space-between;
}

.register-step2-form .form-group.col-md-6 {
	flex: 0 0 48%;
}

.remove-button {
	padding-top: 23px;
	background: none;
	border: none;
	cursor: pointer;
}

.circle-icon-container {
	background: none;
	border: none;
	cursor: pointer;
}

.register-step2-form .circle-icon {
	padding: 5px 50px;
	background-color: #f06331;
	border-color: #f06331;
}

.coupon-success-msg {
	color: #026928;
	margin-top: 10px;
}

.coupon-error-msg {
	color: rgb(220, 53, 69);
	margin-top: 10px;
}

.loader-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(7, 10, 9, 0.4);
	z-index: 9999;
}
.icon-arrow {
	margin-bottom: 50px;
	color: black;
}
.step-indicator {
	margin-top: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 40px;
	margin-bottom: 80px;
}

.step {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	z-index: 1;
}

.step-indicator .step-icon {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background: #c2c2c2;
	font-size: 10px;
	text-align: center;
	color: #ffffff;
	position: relative;
	line-height: 50px;
	font-size: 20px;
}

.step.active .step-icon {
	background: #ff5a00;
}

.step p {
	text-align: center;
	position: absolute;
	top: 60px;
	color: #c2c2c2;
}

.step.active p {
	color: #ff5a00;
}

.step.step2 p {
	left: 50%;
	transform: translateX(-50%);
}

.indicator-line {
	max-width: 30%;
	height: 4px;
	background: #c2c2c2;
	flex: 1;
}

.indicator-line.active {
	background: #ff5a00;
}

@media screen and (max-width: 500px) {
	.step p {
		bottom: -20px;
	}
	.indicator-line {
		min-width: 50%;
	}
}

/* Media query for mobile devices */
@media (max-width: 767px) {
	.register-step2-form .form-row {
		display: block;
	}
}
@media (max-width: 600px) {
	.icon-arrow {
		align-self: center;
		vertical-align: middle;
		margin: 20px;
		transform: rotate(90deg);
	}
	.register-tab ul.nav-tabs {
		align-items: center;
		flex-direction: column;
	}
}
