.my-component-button {
  background-color: #01914c;
  color: white;
  border-radius: 5px;
  padding: 10px;
  /* Add more styles as needed */
}

.wrap--gray-lightest {
  background-color: #f7f7f9;
}

.dashboard-page-title {
  color: #7e7e7e;
  font-size: 1.875rem;
  padding: 1.875rem 0;
  text-align: center;
}

.dashboard-container {
  margin: 0 100px 0 100px;
    margin-top: 50px;
}

.new-camera-container{
  display: flex;
  justify-content: center;
  padding: 1.875rem 0;
  background-color: #f7f7f9;
}
