/* RegisterStep1.css */
.register-page-container1 {
  background-image: radial-gradient(circle, #fff, rgba(42, 42, 42, 0.1));
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow-x: hidden;
  /* justify-content: center; */
}

.register-container1 {
  /* padding-top: 50px; */
  /* padding-bottom: 50px; */
}

.register-container1 h1 {
  background: rgba(7,10,9,0.66);
  color: white;
  padding: 5px 0px;
}

.register-step1-form {
  /* background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1); */
}

.register-step1-form .form-group {
  margin-bottom: 20px;
}

.register-step1-form .form-row {
  display: flex;
  justify-content: space-between;
}

.register-step1-form .form-group.col-md-6 {
  flex: 0 0 48%;
}

.register-step1-form label {
  font-weight: 500;
}

.register-step1-form .form-control{
  font-weight: 300 !important;
}

/* .register-step1-form .btn-primary {
  padding: 5px 50px;
  background-color: #f06331;
  border-color: #f06331;
}

.register-step1-form .btn-primary:hover {
  background-color: #cc4800;
  border-color: #cc4800;
} */

.register-step1-form .show-password-icon {
  height: 100%;
}

.register-step1-form .input-group {
  position: relative;
}

.register-step1-form .show-password-icon {
  position: absolute;
  right: 0px;
  transform:  translateY(-50%);
  cursor: pointer;
}

.register-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgba(7,10,9,0.66);
}



.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}
