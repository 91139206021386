/* Common styles for all screen sizes */
.wrap {
  background-color: black;
  z-index: 255;
}

.logo__image {
  height: 30px;
}

.header-dashboard {
  display: flex;
  align-items: center;
}

.header__logo {
  margin-left: 50px;
  margin-right: auto;
}

.header__nav {
  margin-left: auto;
  margin-right: 100px;
}

.header__nav .hamburger {
  margin-left: auto;
}

.hamburger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: white;
  height: 3px;
  width: 20px;
  margin-bottom: 3px;
}

.navbar {
  list-style: none;
  display: flex;
}

.navbar__item {
  margin-right: 20px;
}

.navbar__item__link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}

.navbar__item__icon {
  margin-right: 5px;
  height: 16px;
  width: 16px;
}

.navbar__item__link:hover {
  color: red;
}

.navbar-submenu {
	position: relative;
	right: 0;
	width: 200px;
	height: auto;
	display: none;
	list-style: none;
	position: absolute;
	background-color: white;
	border-color: #ff5a00;
	border-radius: 5px;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
	/* white-space: nowrap; Prevent text wrapping */
}

.navbar__item--avatar:hover .navbar-submenu {
  display: block;
}

.navbar-submenu__item {
  margin-bottom: 5px;
}

.navbar-submenu__item__link {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; 
	color: black;
	display: block;
	padding-left: 1rem;
	padding-right: 1rem;

	/* width: 100%; */
	text-decoration: none;
}

.navbar-submenu__item__link:hover {
	color: white;
	background-color: #ff5a00;

	transition: 0.1s ease;
	font-weight: 500;
	/* font-size: 17px; */
}

.navbar__avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.navbar__item--is-active .navbar__item__link {
  color: red;
}

/* Media queries for different screen sizes */

/* Mobile styles */
@media (max-width: 767px) {
  .header__logo {
    margin-left: 20px;
  }

  .header__nav {
    margin-right: 20px;
  }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
  .header__logo {
    margin-left: 30px;
  }

  .header__nav {
    margin-right: 50px;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .header__logo {
    margin-left: 50px;
  }

  .header__nav {
    margin-right: 100px;
  }
}
