.header {
	width: 100%;
	background: url(../../../public/assets/img/bg-01.jpg) no-repeat center top
		#010b33;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	margin-bottom: 0px;
}
.text-container {
	padding: 10px 0 70px 0;
}
