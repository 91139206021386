/* Custom CSS for DeviceList component */


.cameras-container h2 {
  color: #333;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  color: #fff;
  font-weight: bold;
  padding: 12px;
  text-align: center;
  vertical-align: middle;
  background-color: #343a40;
}

td {
  padding: 12px;
  text-align: center;
  vertical-align: middle;
}

.cameras-container .btn {
  padding: 6px 12px;
}
