/* PlaybackPlayer.css */

/* Style for the main container holding the video player and navigation drawer */
.video-player-container {
	display: flex;
	height: 100vh;
	overflow: hidden;
	background-color: transparent;
}

/* Style for the navigation drawer */
.navigation-drawer {
	height: 100%;
	width: 300px;
	background-color: rgba(0, 0, 0, 0.8);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	transition: transform 0.3s ease;
	transform: translateX(-100%);
	position: absolute;
	left: 0;
	z-index: 3;
	scrollbar-width: thin;
	scrollbar-track-color: #ff5a00;
	scrollbar-color: #ff5a00;
	text-align: center;
	font-size: 20px;
	/* overflow-y: hidden; */
}

.navigation-drawer.open {
	transform: translateX(0%);
}
.navigation-drawer.close {
	transform: translateX(-100%);
}

/* Style for the navigation drawer toggle button */
.nav-toggle-button {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	height: 250px;
	width: 50px;
	position: absolute;
	top: 20vh;
	left: 100%;
}
.nav-icon {
	height: 50px;
	width: 50px;
	color: #ff5a00;
	cursor: pointer;
}
.nav-icon:hover {
	height: 60px;
	width: 60px;

	transform: transition(5%);
}
element.style {
	width: 200px;
}

/* Style for the navigation drawer content */
.nav-content {
	padding: 15px;
	display: none;
	overflow-y: auto;
	max-height: 100vh;
	scrollbar-track-color: #ff5a00;
	scrollbar-color: #ff5a00;
}

.nav-content h3 {
	margin-bottom: 20px;
}

/* Style for the nested list in the navigation drawer */
ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

li {
	margin-bottom: 8px;
}

ul ul {
	margin-left: 10px;
}

/* Style for the video container */
.video-container {
	flex: 1;
	width: 100vw;
	background-color: #333;
	display: flex;
	justify-content: center;
	align-items: center;
}

video {
	min-width: 100%;
	height: 100vh;
}

.collapsible-content-inner {
	color: white;
}

.collapsible-content-inner p {
	cursor: pointer;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 70px;
	flex-direction: row;
}
.collapsible-content-inner p:hover {
	width: auto;
	font-size: 21px;
	transform: translateX(5%);
	color: #fe8848;
	transition: color 0.3s ease;
	/* background-color: rgba(255, 255, 255, 0.3); */
}
.collapsible-content-inner {
	margin-bottom: 10px;
}

.collapsible-trigger {
	color: white;
	cursor: pointer;
}

.p {
	color: white;
}
.nav-content {
	color: white;
}
.arrow-icon-wrapper {
	display: none;
}
.arrow-icon {
	width: 24px;
	height: 24px;
}
.collapsible-content-inner p:hover .arrow-icon-wrapper {
	display: inline-block;
	transition: 0.1s ease;
}
.collapsible-trigger:hover {
	color: #fe8848;
	display: inline-block;
	transition: 0.1s ease;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.2);
}
.collapsible-trigger:hover .arrow-icon-wrapper {
	color: #fe8848;
	display: inline-block;
	transition: 0.1s ease;
}
.collapsible-trigger-open .arrow-icon-wrapper {
	color: #fe8848;
	display: inline-block;
	transition: 0.1s ease;
}

.hour-collapse {
	margin-top: 10px;
	margin-bottom: 10px;
}
.collapsible {
	margin-bottom: 10px;
}

@media (max-width: 500px) {
	.navigation-drawer {
		width: 200px;
		text-align: start;
	}
	.collapsible-content-inner p {
		align-items: flex-start;
		justify-content: center;
	}
	.nav-icon {
		height: 30px;
		width: 30px;
		color: #ff5a00;

		cursor: pointer;
	}
	.nav-icon:hover {
		height: 40px;
		width: 40px;

		transform: transition(5%);
	}
	.nav-toggle-button {
		top: 20vh;
	}
	.video {
		height: 100%;
	}
	.collapsible-content-inner p {
		margin-left: 10px;
	}
}
@media (min-width: 1500px) {
	.video {
		height: 100vh;
	}
}
